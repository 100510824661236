<template>
  <v-card elevation="1">
    <v-toolbar height="45" flat color="accent" light>
      <v-toolbar-title class="white--text">{{ $route.meta.title }}</v-toolbar-title>
    </v-toolbar>
    <v-divider class="mb-2"></v-divider>
    <v-tabs color="accent">
      <v-tab>
        <v-icon v-text="icons.mdiPlus" left></v-icon>
        Vehicle Info
      </v-tab>
      <v-tab>
        <v-icon v-text="icons.mdiInformation" left></v-icon>
        Additional Info
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-form class="multi-col-validation">
              <v-row>
                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="vehicle.vehicle_name"
                    color="secondary"
                    label="Vehicle Name"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col md="6" cols="12">
                  <v-text-field v-model="vehicle.year" color="secondary" label="Year" dense outlined></v-text-field>
                </v-col>
                <v-col md="6" cols="12">
                  <v-text-field v-model="vehicle.make" color="secondary" label="Make" dense outlined></v-text-field>
                </v-col>
                <v-col md="6" cols="12">
                  <v-text-field v-model="vehicle.model" color="secondary" label="Model" dense outlined></v-text-field>
                </v-col>
                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="vehicle.sub_model"
                    color="secondary"
                    label="SubModel (Optional)"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="6" cols="12">
                  <v-text-field v-model="vehicle.type" color="secondary" label="Type" dense outlined></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="vehicle.kilometer"
                    dense
                    label="Kilometers (Optional)"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-form class="multi-col-validation">
              <v-row>
                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="vehicle.license_plate"
                    color="secondary"
                    label="License Plate"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="vehicle.unit_number"
                    color="secondary"
                    label="Unit Number (Optional)"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="vehicle.vin" color="secondary" label="Vin" dense outlined></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="vehicle.drive_train"
                    dense
                    label="Drive Train (Optional)"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea
                    background-color="transparent"
                    v-model="vehicle.notes"
                    color="secondary"
                    dense
                    label="Notes (Optional)"
                    outlined
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="vehicle.color"
                    hide-details
                    color="secondary"
                    dense
                    label="Color (Optional)"
                    outlined
                  >
                    <template v-slot:append>
                      <v-menu
                        v-model="menu"
                        top
                        nudge-bottom="105"
                        nudge-left="16"
                        :close-on-content-click="false"
                        solo
                      >
                        <template v-slot:activator="{ on }">
                          <div :style="swatchStyle" class="mt-n1" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker v-model="vehicle.color" flat />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="vehicle.engine_model"
                    dense
                    label="Engine Size (Optional)"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="vehicle.production_date"
                    dense
                    label="Production Date (Optional)"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="vehicle.transmission"
                    dense
                    label="Transmission (Optional)"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="accent" rounded @click.prevent="addNewVehicle">
              <v-icon v-text="icons.mdiContentSave" right light></v-icon>
              <span>Save</span>
            </v-btn>
            <v-btn color="secondary" rounded @click.prevent="$router.push({ name: 'vehicle' })">
              <v-icon v-text="icons.mdiClose" right light></v-icon>
              <span>Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>
  
  <script>
import { mdiAccountTie, mdiPlus, mdiInformation, mdiContentSave, mdiClose } from '@mdi/js'
import { mapGetters } from 'vuex'
export default {
  name: 'AddNewVehicle',
  components: {},
  data() {
    return {
      vehicle: {
        vehicle_name: '',
        make: '',
        model: '',
        type: '',
        year: '',
        license_plate: '',
        kilometer: '',
        license_plate: '',
        unit_number: '',
        vin: '',
        drive_train: '',
        color: '#1976D2FF',
        engine_model: '',
        production_date: '',
        transmission: '',
        notes: '',
      },

      menu: false,
      items: ['Mobile', 'Work', 'Home', 'Other'],
      steps: 1,
      totalsteps: 2,
      icons: {
        mdiAccountTie,
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiClose,
      },
    }
  },
  methods: {
    addNewVehicle: function () {
      this.$store
        .dispatch(`vehicle/storeNewVehicle`, this.vehicle)
        .then(response => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })

          Toast.fire({
            icon: 'success',
            title: 'Record added successfully',
          })
          this.$router.push({ name: 'vehicle' })
        })
        .catch(error => {
          console.log(error)
        })
    },
  },

  computed: {
    ...mapGetters({
      fetchMakes: 'loadVehicle/fetchMakes',
    }),

    swatchStyle: function () {
      const { color, menu } = this
      return {
        backgroundColor: this.vehicle.color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out',
      }
    },
  },
}
</script>
  
  <style lang="scss" scoped>
</style>